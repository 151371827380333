import { Button } from 'react-bootstrap';

interface Props {
  onClick: () => void,
}

const RemoveButton = (props: Props) => {
  const { onClick } = props;
  return (
    <div
      style={{
        marginTop: '8px',
        marginLeft: '-32px',
        zIndex: 2,
      }}
    >
      <Button
        className="btn btn-sm btn-danger"
        onClick={() => onClick()}
      >x</Button>
    </div>
  );
};

export default RemoveButton;
