export const getNewComapny = (id: string) => ({
  id,
  name: '',
  OIB: '',
  address: '',
  city: '',
  employers: [],
});

export const getNewEmployer = (id: string) => ({
  id,
  name: '',
  OIB: '',
  startDate: '',
  autofillConfig: null,
  hours: { weekly: 40 },
});
