import { every, isEmpty } from "lodash"
import { AutoFillRule, AutoFillRuleValidationError, ValidationError } from "../../../../../types";
import RulesSummary from "../utils/rulesSummary";

export type Validator = (value?: string) => ValidationError;
export type AutoFillRuleValidator = (year: number, value?: AutoFillRule[]) => AutoFillRuleValidationError[] | null;

export const required: Validator = (value?: string) => {
  if (isEmpty(value)) {
    return "Obavezno polje";
  }
  return null;
}

export const digitsOnly: Validator = (value?: string) => {
  if (!value) {
    return null;
  }
  if (value.match(/\D/)) {
    return "Dozvoljeni su samo brojevi";
  }
  return null;
}

export const fixedLength = (length: number): Validator => (value: string = '') => {
  if (value.length !== length) {
    return `OIB mora imati ${length} znamenki`;
  }
  return null;
}

export const oibValidator: Validator = (value?: string) => {
  const requiredError = required(value);
  if (requiredError) {
    return requiredError;
  }
  const digitsOnlyError = digitsOnly(value);
  if (digitsOnlyError) {
    return digitsOnlyError;
  }
  const fixedLengthError = fixedLength(11)(value);
  if (fixedLengthError) {
    return fixedLengthError;
  }
  return null;
};

export const autoFillRulesValidator: AutoFillRuleValidator = (year: number, rules: AutoFillRule[] = []) => {
  const summary = new RulesSummary(rules, year);
  const errors = summary.getValidationErrors();
  if (every(errors, isEmpty)) {
    return null;
  }
  return summary.getValidationErrors()
}