import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, WizardData, WizardStep } from '../types';

const initialState: WizardData = {
  activeStep: 'FORM',
  continueDisabledMessage: '',
};

const wizardSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<WizardStep>) => {
      state.activeStep = action.payload;
    },
    setContinueDisabledMessage: (state, action: PayloadAction<string>) => {
      state.continueDisabledMessage = action.payload;
    },
  },
});

export const getActiveStep = (state: RootState) => state.wizard.activeStep;
export const getContinueDisabledMessage = (state: RootState) => state.wizard.continueDisabledMessage;
export const isContinueDisabled = (state: RootState) => !!state.wizard.continueDisabledMessage;

export const { setActiveStep, setContinueDisabledMessage } = wizardSlice.actions;

export default wizardSlice.reducer;