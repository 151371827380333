import { identity, isEmpty, pickBy } from "lodash";
import { AutoFillRuleValidationError, EmployerValidationErrorsMap } from "../../../../../types";
import { MainFormValidationErrorsMap } from "../../CompanyForm/validation/types";

type Error =
  | EmployerValidationErrorsMap
  | AutoFillRuleValidationError
  | MainFormValidationErrorsMap;

export const hasErrors = (validationErrorsMap: Error) => {
  return !isEmpty(pickBy(validationErrorsMap, identity));
} 