import { create } from 'apisauce';
import { ErvFormState } from './types';

const api = create({
  // baseURL: 'http://127.0.0.1:5000',
  baseURL: 'https://erv-api.codes.hr',
  timeout: 3600000000,
});

export const postErv = (form: ErvFormState) => {
  return api.post('/erv', form);
}

export default api;
