import { Form } from 'react-bootstrap';
import { FormData } from '../../../types';
import InfoTooltip from './InfoTooltip';
import FormListHeader from './FormListHeader';
import CompanyForm from './CompanyForm';
import NumericFormControl from './Form/NumericFormControl';
import { useContext } from 'react';
import { MainFormValidationContext } from './CompanyForm/validation/MainFormValidationContext';
import StringFormControl from './Form/StringFormControl';
import { getNewComapny } from './constant';
import { v4 } from 'uuid';

interface Props {
  formData: FormData;
  onChange: (f: FormData) => void;
}

const MainForm = (props: Props) => {
  const { formData, onChange } = props;
  const { validationErrors } = useContext(MainFormValidationContext);
  return (
    <>
      <Form className='mgl mgr'>
        <Form.Group controlId="year" className="mgb">
          <Form.Label>
            Godina
            <InfoTooltip
              text="Trenutno je podržana samo tekuća godina. Ako želite kreirati ERV dokument za neku drugu godinu, kontaktirajte nas na davor.obilinovic@gmail.com"
              bottom
            />
          </Form.Label>
          <NumericFormControl
            value={formData.year}
            onChange={(e) => {
              const year = parseInt(`${e.target.value}`);
              onChange({ ...formData, year });
            }}
            validationError={validationErrors.year}
            readonly
          />
        </Form.Group>
        <Form.Group controlId="password" className="mgb">
          <Form.Label title='password'>
            Lozinka
            <InfoTooltip
              text="Zapamtite unesenu lozinku. Za izmjenu pojedinih podataka unutar erv dokumenta, MsExcel vas može zatražiti unos lozinke."
            />
          </Form.Label>
          <StringFormControl
            value={formData.password}
            onChange={(e) => {
              const password = e.target.value;
              onChange({ ...formData, password });
            }}
            validationError={validationErrors.password}
          />
        </Form.Group>
        <FormListHeader
          label="Firme:"
          onAdd={() => {
            const newCompany = getNewComapny(v4());
            onChange({ ...formData, companies: [...formData.companies, newCompany] });
          }}
          addButtonDisabled={validationErrors.companies !== null}
          addButtonDisabledTooltip='Ispravite nesipravno unesene podatke o formi.'
        />
        {formData.companies.map((company, index) => (
          <CompanyForm
            key={company.id}
            company={company}
            onChange={(newCompany) => {
              const nextCompanies = formData.companies.map(c => c.id === company.id ? newCompany : c);
              onChange({ ...formData, companies: nextCompanies });
            }}
            onRemoveCompany={(companyToRemove) => {
              const nextCompanies = formData.companies.filter((c) => c.id !== companyToRemove.id);
              onChange({ ...formData, companies: nextCompanies });
            }}
            validationErrors={validationErrors.companies?.[index]}
          />
        ))}
      </Form>
    </>
  );
};

export default MainForm;
