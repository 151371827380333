import './App.css';
import Wizard from './components/Wizard';

const App = () => {
  return (
    <Wizard />
  );
};

export default App;
