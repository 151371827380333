import { AutoFillRule } from "../../../types";

const LAST_SAVED_RULE_KEY = 'lastSavedRule';

export const getLastSavedRules = (): AutoFillRule[] | null => {
  const serializedData = localStorage.getItem(LAST_SAVED_RULE_KEY);
  if (serializedData) {
    try {
      return JSON.parse(serializedData) as AutoFillRule[];
    } catch (error) {
      console.error('Error parsing last saved rule:', error);
    }
  }
  return null;
}

export const setLastSavedRules = (rules: AutoFillRule[]): void => {
  const serializedData = JSON.stringify(rules);
  localStorage.setItem(LAST_SAVED_RULE_KEY, serializedData);
}