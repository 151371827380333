import React, { ReactNode } from 'react';
import './Flyout.css';
import classNames from 'classnames';
import { FlyoutData } from './types';

type Props = {
  children: ReactNode,
  visible: boolean,
  flyoutData: FlyoutData<any> | null,
};

const Flyout: React.FC<Props> = ({ children, visible, flyoutData }: Props) => (
  <div
    className={classNames('flyout-overlay', { visible })}
    onClick={() => { flyoutData?.reject(); }}
  >
    <div
      className={classNames('flyout-container y-scroll', { visible })}
      onClick={(e) => { e.stopPropagation() }}
    >
      <div className="flyout-content">{children}</div>
    </div>
  </div>
);

export default Flyout;
