import { useDispatch, useSelector } from "react-redux";
import { getActiveStep, getContinueDisabledMessage, isContinueDisabled, setActiveStep } from "../../state/wizardSlice";
import { findIndex } from "lodash";
import classNames from "classnames";
import { WizardStep } from "../../types";
import { ComponentType, useMemo } from "react";
import FormStep from "./FormStep";
import OverviewStep from "./OverviewStep/OverviewStep";
import ButtonWithDisabledTooltip from "./FormStep/EmployerFlyout/components/ButtonWithDisabledTooltip";

type WizardStepConfig = {
  title: string,
  wizardStep: WizardStep,
  Component: ComponentType,
}

const steps: WizardStepConfig[] = [
  {
    wizardStep: 'FORM',
    title: 'Unos podataka',
    Component: FormStep,
  },
  // {
  //   wizardStep: 'INFO',
  //   title: 'Info',
  //   Component: InfoStep,
  // },
  {
    wizardStep: 'OVERVIEW',
    title: 'Generiranje dokumenta',
    Component: OverviewStep,
  },
];

const Wizard = () => {
  const dispatch = useDispatch();
  const activeStep = useSelector(getActiveStep);
  const activeStepIndex = findIndex(steps, (s) => s.wizardStep === activeStep);
  const continueDisabled = useSelector(isContinueDisabled);
  const continueDisabledMessage = useSelector(getContinueDisabledMessage);
  const Component = useMemo(() => (
    steps[activeStepIndex].Component
  ), [activeStepIndex]);
  return (
    <div className="fcol main-page">
      <div className='frow header'>
        <div className="frow f1">
          {steps.map(({ title, wizardStep }, i) => (
            <div key={wizardStep} className="frow">
              {i > 0 && (<div className="mgr">{'>'}</div>)}
              {i < activeStepIndex ? (
                <button
                  className="buttonAsText mgr"
                  onClick={() => dispatch(setActiveStep(wizardStep))}
                >{title}</button>
              ) : (
                <div
                  className={classNames('mgr', {
                    disabled: i > activeStepIndex,
                    active: i === activeStepIndex,
                  })}
                >{title}</div>
              )}
            </div>
          ))}
        </div>
        {activeStepIndex + 1 < steps.length && (
          <div className="frow">
            <ButtonWithDisabledTooltip
              className="btn btn-sm"
              disabled={continueDisabled}
              onClick={() => {
                const nextActiveStep = steps[activeStepIndex + 1];
                dispatch(setActiveStep(nextActiveStep.wizardStep));
              }}
              disabledTooltip={continueDisabledMessage}
              bottom
            >
              Nastavi
            </ButtonWithDisabledTooltip>
          </div>
        )}
      </div>
      <div className='f1'>
        <Component />
      </div>
    </div>
  );
};

export default Wizard;
