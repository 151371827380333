import { Form } from 'react-bootstrap';
import InfoTooltip from '../../InfoTooltip';

interface Props {
  checkBoxLabel: string,
  onValueChange: (nextValue: { hours: number }) => void,
  onCheckChange: (nextChecked: boolean) => void,
  value: undefined | {
    hours: number,
  },
  infoTooltipText?: string,
}

const VacationSelect = (props: Props) => {
  const {
    value,
    onValueChange,
    onCheckChange,
    checkBoxLabel,
    infoTooltipText,
  } = props;
  const { hours } = value || {};
  return (
    <div className='frow mgr ac mgb8 mgl'>
      <div className='frow f1 ac'>
        <Form.Check
          type="checkbox"
          checked={!!value}
          onChange={(e) => onCheckChange(e.target.checked)}
          label={checkBoxLabel}
          inline
        />
      </div>
      <div className='frow f1 ac'>
        Satnica
        <Form.Control
          width="48px"
          type="number"
          min={1}
          max={24}
          className="mgl mgr"
          value={hours || ''}
          disabled={!value}
          onChange={(e) => {
            if (!value) {
              throw new Error('Value is not defined');
            }
            const nextHours = parseInt(e.target.value, 10);
            onValueChange({
              ...value,
              hours: nextHours,
            });
          }}
        />
      </div>
      {infoTooltipText && (
        <InfoTooltip
          className='mgr'
          text={infoTooltipText}
        />
      )}
    </div >
  );
};

export default VacationSelect;
