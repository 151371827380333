import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Company, FormData, RootState } from '../types';

const loadState = (): FormData | undefined => {
  try {
    const serializedState = localStorage.getItem('formData');
    return serializedState ? JSON.parse(serializedState) : undefined;
  } catch (err) {
    console.error('Error loading state from local storage:', err);
    return undefined;
  }
};

const saveState = (state: FormData) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('formData', serializedState);
  } catch (err) {
    console.error('Error saving state to local storage:', err);
  }
};
const loadedState = loadState() || {
  year: new Date().getFullYear(),
  companies: [] as Company[],
  password: '',
};

const initialState: FormData = {
  ...loadedState,
  year: new Date().getFullYear(),
};

const formSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateFormData: (state, action: PayloadAction<FormData>) => {
      const newState = action.payload;
      saveState(newState);
      return newState;
    },
  },
});

export const getFormData = (state: RootState) => state.form;
export const { updateFormData } = formSlice.actions;
export default formSlice.reducer;
