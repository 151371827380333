import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  text: JSX.Element | string;
  bottom?: boolean;
  className?: string;
}

const InfoTooltip = ({ text, bottom, className }: Props) => {
  return (
    <OverlayTrigger
      placement={bottom ? 'bottom' : 'top'}
      overlay={
        <Tooltip id="info-tooltip" >
          {text}
        </Tooltip>
      }
    >
      <span className={classNames("info-icon mgl", className)}>ℹ️</span>
    </OverlayTrigger>
  );
};

export default InfoTooltip;
