import { Form } from 'react-bootstrap';
import InfoTooltip from '../../InfoTooltip';
import { DANI_U_TJEDNU } from '../../helpers';
import classNames from 'classnames';

interface Props {
  onChange: (weekdays: boolean[]) => void,
  value: boolean[],
  label?: string,
  tooltipText?: string,
}

const WeekdaysPicker = (props: Props) => {
  const {
    onChange,
    value: days,
    label = 'Radni dani:',
    tooltipText = 'Ovo pravilo odnosit će se samo na dane u tjednu koji su označeni zelenom bojom.',
  } = props;
  return (
    <div className='frow mgr ac mgb8'>
      <InfoTooltip
        className='mgr'
        text={tooltipText}
      />
      <Form.Label className='mgb0 mgr'>{label}</Form.Label>
      {DANI_U_TJEDNU.map((dan, i) => (
        <div
          key={dan}
          className={classNames('label clickable mgr4', {
            'label-red': !days[i],
            'label-green': days[i],
          })}
          onClick={() => {
            const nextDays = days
              .map((d, di) => di === i ? !d : d);
            onChange(nextDays)
          }}
        >{dan}</div>
      ))}
    </div>
  );
};

export default WeekdaysPicker;
