import { useState } from "react";
import { Card, Form } from "react-bootstrap";
import { isEmpty, isNull } from "lodash";
import { Company, Employer } from "../../../../../types";
import CompanyEmployersTable from "./CompanyEmployersTable";
import FormListHeader from "../../FormListHeader";
import EmployerFlyout from "../../EmployerFlyout/components/EmployerFlyout";
import { FlyoutData } from "../../../../Flyout/types";
import { getNewEmployer } from "../../constant";
import RemoveButton from "./RemoveButton";
import StringFormControl from "../../Form/StringFormControl";
import { CompanyValidationErrors } from "../validation/types";

interface Props {
  company: Company,
  onChange: (c: Company) => void,
  onRemoveCompany: (c: Company) => void,
  validationErrors?: CompanyValidationErrors,
}

const CompanyForm = ({
  company,
  onChange,
  onRemoveCompany,
  validationErrors,
}: Props) => {
  const [flyoutData, setFlyoutData] = useState<FlyoutData | null>(null);
  const handleAddEmployer = () => {
    new Promise<Employer>(
      (resolve, reject) => {
        const data = getNewEmployer(`${company.id}_${company.employers.length + 1}`);
        setFlyoutData({ resolve, reject, data })
      }
    ).then((newEmployer: Employer) => {
      onChange({
        ...company,
        employers: [...company.employers, newEmployer]
      });
      setFlyoutData(null);
    }).catch(() => {
      setFlyoutData(null);
    });
  };
  const handleEditEmployer = (employer: Employer) => {
    new Promise<Employer>(
      (resolve, reject) => {
        const data = employer;
        setFlyoutData({ resolve, reject, data })
      }
    ).then((newEmployer: Employer) => {
      onChange({
        ...company,
        employers: company.employers.map((e) => e.id === newEmployer.id ? newEmployer : e)
      });
      setFlyoutData(null);
    }).catch(() => {
      setFlyoutData(null);
    });
  };
  const handleRemoveEmployer = (employerToRemove: Employer) => {
    onChange({
      ...company,
      employers: company.employers.filter((e) => e.id !== employerToRemove.id),
    });
  }
  return (
    <div className="frow">
      <EmployerFlyout visible={!isNull(flyoutData)} flyoutData={flyoutData} />
      <Card key={company.id} className="mb-3 full-width">
        <Card.Body>
          <Form.Group controlId={`companyName_${company.id}`} className="mgb">
            <Form.Label>Naziv firme</Form.Label>
            <StringFormControl
              value={company.name}
              onChange={(e) => {
                const name = e.target.value;
                onChange({ ...company, name });
              }}
              placeholder="Unesite naziv firme"
              validationError={validationErrors?.name}
            />
          </Form.Group>

          <Form.Group controlId={`companyOIB_${company.id}`} className="mgb">
            <Form.Label>OIB firme</Form.Label>
            <StringFormControl
              value={company.OIB}
              onChange={(e) => {
                const OIB = e.target.value;
                onChange({ ...company, OIB });
              }}
              placeholder="Unesite firmin OIB"
              validationError={validationErrors?.OIB}
            />
          </Form.Group>

          <Form.Group controlId={`companyAddress_${company.id}`} className="mgb">
            <Form.Label className="mgb0">Adresa</Form.Label>
            <StringFormControl
              value={company.address}
              onChange={(e) => {
                const address = e.target.value;
                onChange({ ...company, address });
              }}
              placeholder="Unesite adresu firme"
              validationError={validationErrors?.address}
            />
          </Form.Group>

          <Form.Group controlId={`companyCity_${company.id}`} className="mgb">
            <Form.Label>Grad</Form.Label>
            <StringFormControl
              value={company.city}
              onChange={(e) => {
                const city = e.target.value;
                onChange({ ...company, city });
              }}
              placeholder="Unesite grad firme"
              validationError={validationErrors?.city}
            />
          </Form.Group>

          <div className='separator' />
          <Form.Group>
            <FormListHeader
              label="Zaposlenici:"
              onAdd={handleAddEmployer}
            />
            {!isEmpty(company.employers) && (
              <CompanyEmployersTable
                employers={company.employers}
                onEdit={handleEditEmployer}
                onRemove={handleRemoveEmployer}
              />
            )}
          </Form.Group>
        </Card.Body>
      </Card>
      <RemoveButton onClick={() => onRemoveCompany(company)} />
    </div>
  );
};

export default CompanyForm;
