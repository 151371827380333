import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfoData, RootState } from '../types';

const initialState: InfoData = {
  email: '',
};

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    setInfoData: (state, action: PayloadAction<InfoData>) => {
      return action.payload;
    },
  },
});

export const getInfoData = (state: RootState) => state.info;
export const { setInfoData } = infoSlice.actions;
export default infoSlice.reducer;