import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { v4 } from 'uuid';
import { getFormData, updateFormData } from '../../../state/formSlice';
import MainFormValidationContextProvider from './CompanyForm/validation/MainFormValidationContext';
import { getNewComapny } from './constant';
import MainForm from './MainForm';
import { FormData } from '../../../types';

const FormStep: React.FC = () => {
  const dispatch = useDispatch();
  const formData = useSelector(getFormData);
  // const { companies } = formData;
  // const setCompanies = useCallback(
  //   (newCompanies: Company[]) => {
  //     dispatch(updateFormData({ ...formData, companies: newCompanies }));
  //   }, [dispatch, formData]);
  // const setYear = useCallback(
  //   (year: number) => {
  //     dispatch(updateFormData({ ...formData, year }));
  //   }, [dispatch, formData]);
  // const setPassword = useCallback(
  //   (password: string) => {
  //     dispatch(updateFormData({ ...formData, password }));
  //   }, [dispatch, formData]);


  // const handleAddCompany = () => {
  //   setCompanies([
  //     ...companies,
  //     getNewComapny(v4()),
  //   ]);
  // };

  // const handleAddEmployer = (companyId: string) => (employer: Employer) => {
  //   setCompanies(
  //     companies.map((company) =>
  //       company.id === companyId
  //         ? {
  //           ...company,
  //           employers: [
  //             ...company.employers,
  //             employer,
  //           ],
  //         }
  //         : company
  //     )
  //   );
  // };

  // const isCompanyNameUniq = (name: string) => {
  //   return isUndefined(find(companies, { name }));
  // }

  const onChange = (newFormData: FormData) => {
    dispatch(updateFormData(newFormData));
  };

  useEffect(() => {
    if (isEmpty(formData?.companies)) {
      const nextCompanies = [getNewComapny(v4())];
      onChange({ ...formData, companies: nextCompanies });
    }
  }, [formData?.companies])
  return (
    <MainFormValidationContextProvider form={formData}>
      <MainForm onChange={onChange} formData={formData} />
    </MainFormValidationContextProvider>
  );
};

export default FormStep;
