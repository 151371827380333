import { first, isEmpty, last } from "lodash";

export const DANI_U_TJEDNU = ['Pon', 'Uto', 'Sri', 'Cet', 'Pet', 'Sub', 'Ned'];

const toRangeString = (range: string[]) => {
  if (range.length < 3) return range.join(', ');
  return `${first(range)}-${last(range)}`;
}

const getRanges = (dani: number[]) => {
  const ranges = [];
  let currentRange = [DANI_U_TJEDNU[0]];
  for (let i = 1; i < 7; ++i) {
    if (dani[i]) {
      currentRange.push(DANI_U_TJEDNU[i]);
      continue;
    }
    if (!isEmpty(currentRange)) {
      ranges.push(toRangeString(currentRange));
    }
    currentRange = [];
  }
  if (!isEmpty(currentRange)) {
    ranges.push(toRangeString(currentRange));
  }
  return ranges;
}
export const daniString = (dani: number[]) => {
  const ranges = getRanges(dani);
  return ranges.join(', ');
}