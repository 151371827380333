import React, { PropsWithChildren, useEffect } from "react";
import { FormData } from "../../../../../types";
import { DebouncedFunc, debounce } from "lodash";
import { MainFormValidationErrorsMap } from "./types";
import { companiesValidator, passwordValidator, yearValidator } from "./validators";
import { hasErrors } from "../../EmployerFlyout/validation/helpers";
import { useDispatch } from "react-redux";
import { setContinueDisabledMessage } from "../../../../../state/wizardSlice";


const validators = {
  year: yearValidator,
  password: passwordValidator,
  companies: companiesValidator,
};

type MainFormValidationContextType = {
  validate: () => boolean;
  debouncedValidate: DebouncedFunc<() => boolean>;
  validationErrors: MainFormValidationErrorsMap;
}

export const MainFormValidationContext = React.createContext<MainFormValidationContextType>({
  validate: () => true,
  debouncedValidate: debounce(() => true, 100),
  validationErrors: {},
});

type ProviderProps = PropsWithChildren<{ form: Partial<FormData> }>;
const MainFormValidationContextProvider: React.FC<ProviderProps> = ({ children, form }) => {
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = React.useState<MainFormValidationErrorsMap>({});
  const validate = () => {
    const errors: MainFormValidationErrorsMap = {};
    errors.year = validators.year(form.year);
    errors.password = validators.password(form.password);
    errors.companies = validators.companies(form.companies);
    setValidationErrors(errors);
    const hasAnyErrors = hasErrors(errors);
    return !hasAnyErrors;
  };
  const debouncedValidate = debounce(validate, 200);
  useEffect(() => { debouncedValidate(); }, [form]);
  const hasAnyErrors = hasErrors(validationErrors);
  useEffect(() => {
    const message = hasAnyErrors ? 'Prije nastavka ispravite nesipravno unesena polja.' : '';
    dispatch(setContinueDisabledMessage(message));
  }, [hasAnyErrors]);
  return (
    <MainFormValidationContext.Provider value={{ validate, debouncedValidate, validationErrors }}>
      {children}
    </MainFormValidationContext.Provider>
  );
};

export default MainFormValidationContextProvider;