import moment, { Moment } from 'moment';

export const DATE_FORMAT = 'DD/MM/YYYY';

export const isDateStringValid = (dateString: string) => {
  const m = moment(dateString, DATE_FORMAT, true);
  return m.isValid();
}

export const getDateIndex = (dateString: string) => {
  const date = moment(dateString, DATE_FORMAT);
  const startOfYear = moment(date).startOf('year');
  const diff = date.diff(startOfYear, 'days');
  return diff;
};

export const getDayOfWeekIndex = (moment: Moment) => {
  return moment.day() === 0 ? 6 : moment.day() - 1;
}

export const getNumberOfDaysInAYear = (year: number) => {
  const startOfYear = moment().year(year).startOf('year');
  const endOfYear = moment().year(year).endOf('year');
  return endOfYear.diff(startOfYear, 'days') + 1;
}

export const getMomentsRange = (startDate: string, endDate: string) => {
  const start = moment(startDate, DATE_FORMAT);
  const end = moment(endDate, DATE_FORMAT);
  const diff = end.diff(start, 'days');
  return Array.from({ length: diff + 1 }, (_, i) => moment(start).add(i, 'days'));
}

export const isDateStringInYear = (dateString: string, year: number) => {
  const date = moment(dateString, DATE_FORMAT);
  return date.year() === year;
}

export const isDateStringInOrder = (startDate: string, endDate: string) => {
  const start = moment(startDate, DATE_FORMAT);
  const end = moment(endDate, DATE_FORMAT);
  return !end.isBefore(start);
}

export const isMomentInSelectedDays = (selectedDays: boolean[]) => (moment: Moment) => {
  const dayIndex = getDayOfWeekIndex(moment);
  return selectedDays[dayIndex];
}