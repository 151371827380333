import { ChangeEventHandler } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import './FormControlStyles.css';
import { ValidationError } from '../../../../types';

type StringFormControlProps = {
  className?: string,
  validationError?: ValidationError,
  value: string,
  onChange: ChangeEventHandler<{ value: string }>,
  autoFocus?: boolean,
  placeholder?: string,
}

const StringFormControl = (props: StringFormControlProps) => {
  const {
    className,
    validationError,
    value,
    onChange,
    autoFocus,
    placeholder,
  } = props;
  return (
    <div className={classNames('pos-rel', className)}>
      <Form.Control
        type="string"
        className={classNames({ 'errored-border': !!validationError })}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        placeholder={placeholder}
      />
      {!!validationError && (
        <OverlayTrigger
          key="validationError"
          placement="top"
          overlay={<Tooltip id="valdiationErrorTooltip">{validationError}</Tooltip>}
        >
          <span className="error-icon">&#9888;</span>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default StringFormControl;
