import { Button, Form } from 'react-bootstrap';
import ButtonWithDisabledTooltip from './EmployerFlyout/components/ButtonWithDisabledTooltip';

interface Props {
  label: string,
  onAdd: () => void,
  addButtonText?: string,
  addButtonDisabled?: boolean,
  addButtonDisabledTooltip?: string,
  before?: React.ReactNode,
  after?: React.ReactNode,
}

const FormListHeader = ({
  label,
  onAdd,
  addButtonText = "Dodaj",
  addButtonDisabled = false,
  addButtonDisabledTooltip,
  before,
  after,
}: Props) => {
  return (
    <div className="frow ac">
      <span style={{ marginBottom: '.5rem' }}>
        {before}
      </span>
      <Form.Label className="mgr">{label}</Form.Label>
      <ButtonWithDisabledTooltip
        className="btn-sm btn-success mgb8"
        disabled={addButtonDisabled}
        disabledTooltip={addButtonDisabledTooltip}
        onClick={() => onAdd()}
      >{addButtonText}</ButtonWithDisabledTooltip>

      <span style={{ marginBottom: '.5rem' }}>
        {after}
      </span>
    </div>
  );
};

export default FormListHeader;
