import { Card, Form } from 'react-bootstrap';
import { Employer } from '../../../../../types';
import { useContext } from 'react';
import AutoFillRules from './AutoFillRules';
import { EmployerFormValidationContext } from '../validation/EmployerFormValidationContext';
import StringFormControl from '../../Form/StringFormControl';
import EmployerHoursSelector from './EmployerHoursSelector';


interface Props {
  employer: Partial<Employer>;
  onChange: (e: Partial<Employer>) => void,
}

const EmployerFormFields = ({ employer, onChange }: Props) => {
  const { validationErrors } = useContext(EmployerFormValidationContext);
  return (
    <Card key={employer.id} className="mb-2">
      <Card.Body>
        <Form.Group controlId={`employerName_${employer.id}`} className="mgb">
          <Form.Label>Ime i prezime zaposlenika</Form.Label>
          <StringFormControl
            value={employer.name || ''}
            validationError={validationErrors?.name}
            onChange={(e) => {
              onChange({ ...employer, name: e.target.value });
            }}
            autoFocus
          />
        </Form.Group>

        <Form.Group controlId={`employerOIB_${employer.id}`} className="mgb">
          <Form.Label>OIB zaposlenika</Form.Label>
          <StringFormControl
            value={employer.OIB || ''}
            validationError={validationErrors?.OIB}
            onChange={(e) => {
              onChange({ ...employer, OIB: e.target.value });
            }}
          />
        </Form.Group>

        <div className='separator' />

        <EmployerHoursSelector
          employer={employer}
          onChange={onChange}
        />
        <AutoFillRules employer={employer} onChange={onChange} />
      </Card.Body>
    </Card>
  );
};

export default EmployerFormFields;
