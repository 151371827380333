import { every, isEmpty } from "lodash";
import { Company, ValidationError } from "../../../../../types";
import { digitsOnly, fixedLength } from "../../EmployerFlyout/validation/validators";
import { CompanyValidationErrors } from "./types";

export const required = (value?: string | number): ValidationError => {
  if (!value || isEmpty(`${value}`.trim())) {
    return 'Obavezno polje';
  }
  return null;
}

export const yearValidator = (value?: number): ValidationError => {
  const requiredError = required(value);
  if (requiredError) {
    return requiredError;
  }
  const numericError = digitsOnly(`${value}`);
  if (numericError) {
    return numericError;
  }
  return null;
}

export const passwordValidator = (value?: string): ValidationError => {
  const requiredError = required(value);
  if (requiredError) {
    return requiredError;
  }
  return null;
}

const companyNameValidator = (name: string = '', companies: Company[]): ValidationError => {
  const requiredError = required(name);
  if (requiredError) {
    return requiredError;
  }
  const companiesWithSameName = companies.filter((company) => company.name === name);
  if (companiesWithSameName.length > 1) {
    return 'Ime tvrtke već postoji';
  }
  return null;
}

const companyOIBValidator = (OIB: string = '', companies: Company[]): ValidationError => {
  const requiredError = required(OIB);
  if (requiredError) {
    return requiredError;
  }
  const numericError = digitsOnly(OIB);
  if (numericError) {
    return numericError;
  }
  const digitsCountError = fixedLength(11)(OIB);
  if (digitsCountError) {
    return digitsCountError;
  }
  // const companiesWithSameOIB = companies.filter((company) => company.OIB === OIB);
  // if (companiesWithSameOIB.length > 1) {
  //   return 'OIB tvrtke već postoji';
  // }
  return null;
}

const validateCompany = (
  company: Partial<Company>,
  i: number,
  companies: Company[]
): CompanyValidationErrors | null => {
  const errors: CompanyValidationErrors = {};
  errors.name = companyNameValidator(company.name, companies);
  errors.OIB = companyOIBValidator(company.OIB, companies);
  errors.address = required(company.address);
  errors.city = required(company.city);
  if (every(errors, isEmpty)) {
    return null;
  }
  return errors;
};

export const companiesValidator = (companies?: Company[]): CompanyValidationErrors[] | null => {
  const errors = companies?.map(validateCompany) || [];
  if (every(errors, isEmpty)) {
    return null;
  }
  return errors;
};
