import { Button } from 'react-bootstrap';
import { AutoFillRule, AutoFillRuleValidationError } from '../../../../../types';
import InfoTooltip from '../../InfoTooltip';
import WeekdaysPicker from './WeekdaysPicker';
import TimePeriodSelect from './TimePeriodSelect';
import VacationSelect from './VacationSelect';
import StringFormControl from '../../Form/StringFormControl';

interface Props {
  rule: AutoFillRule,
  onChange: (e: AutoFillRule) => void,
  onRemove: (e: AutoFillRule) => void,
  validationErrors: AutoFillRuleValidationError,
}

const AutoFillRuleFormGroup = (props: Props) => {
  const { rule, onChange, onRemove, validationErrors } = props;
  const { days, startDate, endDate, first, second, vacation } = rule;
  return (
    <div className='pos-rel'>
      <div className='separator' />
      <div className='frow mgr ac mgb8'>
        <InfoTooltip
          className='mgr'
          text="Unesite datum od kada do kada će se pravilo primjenjivati."
        />
        Datum
        <StringFormControl
          className="mgl mgr"
          validationError={validationErrors?.startDate ?? null}
          value={startDate}
          onChange={(e) => {
            const nextStartDate = e.target.value;
            onChange({
              ...rule,
              startDate: nextStartDate,
            });
          }}
        />
        do
        <StringFormControl
          className="mgl mgr"
          validationError={validationErrors?.endDate ?? null}
          value={endDate}
          onChange={(e) => {
            const nextEndDate = e.target.value;
            onChange({
              ...rule,
              endDate: nextEndDate,
            });
          }}
        />
        {/* <Form.Control
          width="48px"
          type="string"
          className="mgl mgr"
          value={endDate}
          onChange={(e) => {
            const nextEndDate = e.target.value;
            onChange({
              ...rule,
              endDate: nextEndDate,
            });
          }}
        /> */}
      </div>
      <div className='fcol'>
        <WeekdaysPicker
          label="Na Dane:"
          value={days}
          onChange={(nextDays) => {
            onChange({
              ...rule,
              days: nextDays,
            });
          }}
        />
        <TimePeriodSelect
          checkBoxLabel='Prvi dolazak'
          infoTooltipText='Redovni dolazak na posao.'
          value={first}
          onValueChange={(nextValue) => {
            onChange({
              ...rule,
              first: nextValue,
            });
          }}
          onCheckChange={(nextChecked) => {
            if (!nextChecked) {
              onChange({
                ...rule,
                first: undefined,
              });
            } else {
              onChange({
                ...rule,
                vacation: undefined,
                first: {
                  start: 8,
                  end: 16,
                },
              });
            }
          }}
        />
        <TimePeriodSelect
          checkBoxLabel='Drugi dolazak'
          infoTooltipText='Unosi se samo u slučaju kad zaposlenik radi dvije smjene.'
          value={second}
          onValueChange={(nextValue) => {
            onChange({
              ...rule,
              second: nextValue,
            });
          }}
          onCheckChange={(nextChecked) => {
            if (!nextChecked) {
              onChange({
                ...rule,
                second: undefined,
              });
            } else {
              onChange({
                ...rule,
                vacation: undefined,
                second: {
                  start: 16,
                  end: 24,
                },
              });
            }
          }}
        />
        <VacationSelect
          checkBoxLabel='Godišnji odmor'
          infoTooltipText='Moguće je automatizirati unos godišnjeg odmora ako se datumi znaju unaprijed. Na dane godišnjeg odmora pravila o automatskom unosu dolazaka će biti ignorirana. Uobičajeno je da se za godišnji odmor unosi 8 sati dnevno, ali po potrebi moguće je definirati i drugačiju satnicu.'
          value={vacation}
          onValueChange={(nextValue) => {
            onChange({
              ...rule,
              vacation: nextValue,
            });
          }}
          onCheckChange={(nextChecked) => {
            if (!nextChecked) {
              onChange({
                ...rule,
                vacation: undefined,
              });
            } else {
              onChange({
                ...rule,
                first: undefined,
                second: undefined,
                vacation: {
                  hours: 8,
                }
              });
            }
          }}
        />
      </div>
      <div
        style={{
          top: '24px',
          right: '-8px',
          zIndex: 2,
          position: 'absolute',
        }}
      >
        <Button
          className="btn btn-sm btn-danger"
          onClick={() => onRemove(rule)}
        >x</Button>
      </div>
    </div>
  );
};

export default AutoFillRuleFormGroup;
