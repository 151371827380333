import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Employer } from '../../../../../types';
import Flyout from '../../../../Flyout/Flyout';
import { FlyoutData } from '../../../../Flyout/types';
import { setLastSavedRules } from '../../lastSavedRuleLS';
import EmployerFormValidationContextProvider from '../validation/EmployerFormValidationContext';
import EmployerForm from './EmployerForm';
import { useSelector } from 'react-redux';
import { getFormData } from '../../../../../state/formSlice';


interface Props {
  flyoutData: FlyoutData<Employer> | null,
  visible: boolean,
}

const EmployerFlyout = ({ flyoutData, visible }: Props) => {
  const initialEmployer = flyoutData?.data as Partial<Employer>;
  const resolve = flyoutData?.resolve;
  const reject = flyoutData?.reject;
  const { year } = useSelector(getFormData);
  const [employer, setEmployer] = useState({ ...initialEmployer || {} });
  useEffect(() => {
    setEmployer(initialEmployer);
  }, [initialEmployer])
  const onSave = () => {
    if (employer?.autoFillRules && !isEmpty(employer?.autoFillRules)) {
      setLastSavedRules(employer?.autoFillRules);
    }
    resolve?.(employer);
  }
  const onCancel = () => {
    reject?.();
  }
  return (
    <Flyout visible={visible} flyoutData={flyoutData}>
      <EmployerFormValidationContextProvider employer={employer} year={year}>
        <EmployerForm
          employer={employer}
          onChange={(newEmployer) => { setEmployer(newEmployer); }}
          onSave={onSave}
          onCancel={onCancel}
        />
      </EmployerFormValidationContextProvider>
    </Flyout >

  );
};

export default EmployerFlyout;
