import classNames from 'classnames';
import { ChangeEventHandler } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ValidationError } from '../../../../types';
import './FormControlStyles.css';

interface Props {
  value: number;
  onChange: ChangeEventHandler<{ value: number }>,
  readonly?: boolean,
  className?: string,
  validationError?: ValidationError,
}

const NumericFormControl = (props: Props) => {
  const { value, onChange, readonly, className, validationError } = props;
  return (
    <div className={classNames('pos-rel', className)}>
      <Form.Control
        type="number"
        value={value}
        onChange={(e) => onChange(e as any)}
        readOnly={readonly}
      />
      {!!validationError && (
        <OverlayTrigger
          key="validationError"
          placement="top"
          overlay={<Tooltip id="valdiationErrorTooltip">{validationError}</Tooltip>}
        >
          <span className="error-icon">&#9888;</span>
        </OverlayTrigger>
      )}
    </div>
  );
};

export default NumericFormControl;
