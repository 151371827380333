import { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';

interface Props {
  count: number;
}
const Waiting = ({ count }: Props) => {
  const [current, setCurrent] = useState(1);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  useEffect(() => {
    if (current > count && intervalRef.current) {
      clearInterval(intervalRef.current);
      return;
    }
    if (current === 1) {
      intervalRef.current = setInterval(() => {
        setCurrent((current) => current + 1);
      }, 5000);
    }
  }, [current, count]);
  return (
    <Card>
      <Card.Body>
        <Card.Subtitle className="mb-2 text-muted">Molim pricekajte...</Card.Subtitle>
        {current <= count ? (
          <Card.Text className="mb-2 text-muted">Kreiranje dokumenata: {current}/{count}</Card.Text>
        ) : (
          <Card.Text className="mb-2 text-muted">Finaliziranje...</Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default Waiting;
