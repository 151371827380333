import { Form } from 'react-bootstrap';
import { Employer } from '../../../../../types';
import styles from '../styles/HoursSelector.module.scss';
import InfoTooltip from '../../InfoTooltip';
import { identity, keys, pickBy } from 'lodash';

type HoursType = 'daily' | 'weekly' | 'monthly';

const defaultHoursValue = {
  daily: 8,
  weekly: 40,
  monthly: 160,
};
const initialType: HoursType = 'weekly';

const hoursLabels = {
  daily: 'Dnevni',
  weekly: 'Tjedni',
  monthly: 'Mjesečni',
};
const hoursTypeOptions: HoursType[] = ['daily', 'weekly', 'monthly'];


const getEmployerHoursType = (employer: Partial<Employer>): HoursType => {
  const typeArray = keys(pickBy(employer.hours, identity)) as HoursType[];
  return typeArray.length > 0 ? typeArray[0] : initialType;
};

const getEmployerHoursValue = (employer: Partial<Employer>): number => {
  const type = getEmployerHoursType(employer);
  return employer.hours?.[type] || defaultHoursValue[type];
};

interface Props {
  employer: Partial<Employer>;
  onChange: (e: Partial<Employer>) => void,
}

const EmployerHoursSelector = (props: Props) => {
  const { employer, onChange } = props;
  const hoursType = getEmployerHoursType(employer);
  const hoursValue = getEmployerHoursValue(employer);
  return (
    <Form.Group controlId={`employerHoursType_${employer.id}`}>
      <Form.Label>
        <InfoTooltip
          className='mgr'
          text="Fond sati zaposlenika moguće je definirati na dnevnoj, tjednoj ili mjesečnoj bazi. Ovisno o vašim potrebama."
        />
        Fond Sati
      </Form.Label>
      <div className="frow mgl">
        {hoursTypeOptions.map((type) => (
          <Form.Check
            className='mgr'
            key={type}
            type="radio"
            label={hoursLabels[type]}
            id={`hoursTypeDaily_${employer.id}_${type}`}
            checked={type === hoursType}
            onChange={(e) => {
              if (!e.target.checked) return;
              onChange({ ...employer, hours: { [type]: defaultHoursValue[type] } });
            }}
          />
        ))}
      </div>
      <div className='frow mgl'>
        <div className='frow mgr ac'>
          <Form.Label className='mgb0'>{`${hoursLabels[getEmployerHoursType(employer)]} fond sati`}</Form.Label>
        </div>
        <div style={{ width: '80px' }}>
          <Form.Control
            type="number"
            value={hoursValue}
            onChange={(e) => {
              const v = parseInt(e.target.value, 10);
              onChange({ ...employer, hours: { [hoursType]: v } });
            }}
          />
        </div>
      </div>
    </Form.Group>
  );
};

export default EmployerHoursSelector;
