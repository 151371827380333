import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { every, isNull } from 'lodash';
import { v4 as uuid } from 'uuid';
import { getFormData } from '../../../../../state/formSlice';
import { AutoFillRuleValidationError, Employer } from '../../../../../types';
import FormListHeader from '../../FormListHeader';
import InfoTooltip from '../../InfoTooltip';
import { ReactComponent as UndoIcon } from '../../undo.svg';
import { getLastSavedRules } from '../../lastSavedRuleLS';
import { EmployerFormValidationContext } from '../validation/EmployerFormValidationContext';
import AutoFillRuleFormGroup from './AutoFillRuleFormGroup';

interface Props {
  employer: Partial<Employer>;
  onChange: (e: Partial<Employer>) => void,
}

const AutoFillRules = ({ employer, onChange }: Props) => {
  const { autoFillRules = [] } = employer;
  const { year } = useSelector(getFormData);
  const lastSavedRules = getLastSavedRules();
  const { validationErrors } = useContext(EmployerFormValidationContext);
  const rulesValidationErrors = validationErrors.autoFillRules as AutoFillRuleValidationError[];
  const onAddRule = () => {
    onChange({
      ...employer,
      autoFillRules: [
        ...autoFillRules,
        {
          id: uuid(),
          startDate: `01/01/${year}`,
          endDate: `31/12/${year}`,
          days: [true, true, true, true, true, false, false],
          first: {
            start: 8,
            end: 16,
          },
          second: undefined,
        },
      ],
    });
  }
  return (
    <div className="fcol">
      <div className='separator' />
      <FormListHeader
        label="Automatsko popunjavanje:"
        onAdd={onAddRule}
        addButtonDisabled={!every(rulesValidationErrors, isNull)}
        addButtonDisabledTooltip="Prije dodavanja novog pravila ispravite nesipravno unesena polja."
        addButtonText="Dodaj pravilo"
        before={(
          <InfoTooltip
            className='mgr'
            text="Unosom pravila za automatsko popunjavanje, možete definirati pravila prema kojima će dokument automatski popunjavati dolaske i odlaske s posla za tog zaposlenika. Dolasci i odlasci automatski će se popuniti samo za datume do datuma na koj gledate dokument što znaći da će erv dokument u skvakom trenutku biti ispravno popunjen."
          />
        )}
        after={lastSavedRules ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="info-tooltip" >
                Kopiraj zadnja spremljena pravila.
              </Tooltip>
            }
          >
            <Button
              className="mgl"
              variant="outline-tertiary"
              onClick={() => {
                onChange({
                  ...employer,
                  autoFillRules: lastSavedRules,
                });
              }}
            >
              <UndoIcon />
            </Button>
          </OverlayTrigger>
        ) : null}
      />
      {autoFillRules.map((rule, index) => {
        return (
          <AutoFillRuleFormGroup
            key={rule.id}
            rule={rule}
            onChange={(newRule) => {
              const nextRules = autoFillRules.map((r) => r.id === newRule.id ? newRule : r);
              onChange({
                ...employer,
                autoFillRules: nextRules,
              });
            }}
            onRemove={(ruleToRemove) => {
              const nextRules = autoFillRules.filter((r) => r.id !== ruleToRemove.id);
              onChange({
                ...employer,
                autoFillRules: nextRules,
              })
            }}
            validationErrors={rulesValidationErrors?.[index]}
          />
        );
      })}
    </div>
  );
};

export default AutoFillRules;
