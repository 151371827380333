import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import formReducer from './state/formSlice';
import wizardReducer from './state/wizardSlice';
import infoReducer from './state/infoSlice';
import { createRoot } from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

const store = configureStore({
  reducer: {
    form: formReducer,
    wizard: wizardReducer,
    info: infoReducer,
  },
});

const root = document.getElementById('root') as Element;

// Use createRoot instead of ReactDOM.render
const reactRoot = createRoot(root);

reactRoot.render(
  <Provider store={store}>
    <App />
  </Provider>
);
