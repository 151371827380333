import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PropsWithChildren } from 'react';

type Props = {
  onClick: () => void,
  disabled?: boolean,
  disabledTooltip?: string,
  className?: string,
  bottom?: boolean,
} & PropsWithChildren<{}>;

const ButtonWithDisabledTooltip = (props: Props) => {
  const { onClick, disabled, disabledTooltip, className, children, bottom } = props;
  const button = (
    <Button
      className={className}
      disabled={disabled}
      onClick={onClick}
    >{children}</Button>
  );
  if (disabled && disabledTooltip) {
    return (
      <OverlayTrigger
        placement={bottom ? 'bottom' : "top"}
        overlay={
          <Tooltip id="info-tooltip" >
            {disabledTooltip}
          </Tooltip>
        }
      >
        <div>
          {button}
        </div>
      </OverlayTrigger>
    );
  }
  return button;
};

export default ButtonWithDisabledTooltip;
