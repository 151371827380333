import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { Employer, EmployerValidationErrorsMap } from "../../../../../types";
import { autoFillRulesValidator, oibValidator, required } from "./validators";
import { hasErrors } from "./helpers";
import { DebouncedFunc, debounce } from "lodash";


const validators = {
  name: required,
  OIB: oibValidator,
  autoFillRules: autoFillRulesValidator,
};

type EmployerFormValidationContextType = {
  validate: () => boolean;
  debouncedValidate: DebouncedFunc<() => boolean>;
  validationErrors: EmployerValidationErrorsMap;
}

export const EmployerFormValidationContext = React.createContext<EmployerFormValidationContextType>({
  validate: () => true,
  debouncedValidate: debounce(() => true, 100),
  validationErrors: {},
});

type ProviderProps = PropsWithChildren<{ employer: Partial<Employer>, year: number }>;
const EmployerFormValidationContextProvider: React.FC<ProviderProps> = ({ children, employer, year }) => {
  const [validationErrors, setValidationErrors] = React.useState<EmployerValidationErrorsMap>({});
  const validate = () => {
    const ts = new Date().getTime();
    const errors: EmployerValidationErrorsMap = {};
    errors.name = validators.name(employer?.name);
    errors.OIB = validators.OIB(employer?.OIB);
    errors.autoFillRules = validators.autoFillRules(year, employer?.autoFillRules);
    setValidationErrors(errors);
    console.log('Validation time:', new Date().getTime() - ts);
    const hasAnyErrors = hasErrors(errors);
    return !hasAnyErrors;
  };
  const debouncedValidate = debounce(validate, 200);
  useEffect(() => { debouncedValidate(); }, [employer]);
  return (
    <EmployerFormValidationContext.Provider value={{ validate, debouncedValidate, validationErrors }}>
      {children}
    </EmployerFormValidationContext.Provider>
  );
};

export default EmployerFormValidationContextProvider;