
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import EmployerFormFields from './EmployerFormFields';
import { Employer } from '../../../../../types';
import { EmployerFormValidationContext } from '../validation/EmployerFormValidationContext';
import { useContext, useEffect } from 'react';
import { hasErrors } from '../validation/helpers';
import EmployerFormSaveButton from './ButtonWithDisabledTooltip';
import ButtonWithDisabledTooltip from './ButtonWithDisabledTooltip';

interface Props {
  employer: Partial<Employer>;
  onChange: (e: Partial<Employer>) => void,
  onSave: () => void,
  onCancel: () => void,
}

const EmployerForm = (props: Props) => {
  const { employer, onChange, onSave, onCancel } = props;
  const { validate, validationErrors } = useContext(EmployerFormValidationContext);
  return (
    <div className='fcol'>
      {employer && (
        <EmployerFormFields
          employer={employer}
          onChange={onChange}
        />
      )}
      <div className='frow'>
        <ButtonWithDisabledTooltip
          className="btn btn-sm btn-success mgr"
          disabled={hasErrors(validationErrors)}
          disabledTooltip='Prije spremanja ispravite nesipravno unesena polja.'
          onClick={() => {
            if (!validate()) {
              return;
            }
            onSave();
          }}
        >Spremi</ButtonWithDisabledTooltip>
        <Button
          className="btn btn-sm btn-secondary"
          onClick={onCancel}
        >Odustani</Button>
      </div>
    </div>
  );
};

export default EmployerForm;
