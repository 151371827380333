import { identity, isEmpty, some } from "lodash";
import { AutoFillRule, AutoFillRuleValidationError } from "../../../../../types";
import { DATE_FORMAT, getMomentsRange, isDateStringInOrder, isDateStringInYear, isDateStringValid, isMomentInSelectedDays } from "./dates";
import { hasErrors } from "../validation/helpers";

class RulesSummary {
  first: Record<string, boolean>;
  second: Record<string, boolean>;
  vacation: Record<string, boolean>;
  year: number;
  summedRules: AutoFillRule[];
  summedRulesErrors: AutoFillRuleValidationError[];

  constructor(rules: AutoFillRule[] = [], year: number) {
    this.year = year;
    this.first = {};
    this.second = {};
    this.vacation = {};
    this.summedRules = [];
    this.summedRulesErrors = [];
    rules.forEach((rule) => this.addRule(rule));
  }

  validateRule(rule: AutoFillRule): AutoFillRuleValidationError {
    const errors: AutoFillRuleValidationError = {};
    const { startDate, endDate, days, vacation, first, second } = rule;
    if (!isDateStringValid(startDate)) {
      errors.startDate = `Neispravan format datuma (${DATE_FORMAT})`;
    }
    if (!isDateStringValid(endDate)) {
      errors.endDate = `Neispravan format datuma (${DATE_FORMAT})`;
    }
    if (!isDateStringInYear(startDate, this.year)) {
      errors.startDate = `Datum mora biti u ${this.year}. godini`;
    }
    if (!isDateStringInOrder(startDate, endDate)) {
      errors.startDate = `Datum početka mora biti prije datuma završetka`;
    }
    if (!some(days, identity)) {
      errors.days = 'Morate odabrati barem jedan dan u tjednu';
    }
    if (!isEmpty(errors)) {
      return errors;
    }
    if (vacation) {
      if (vacation.hours <= 0) {
        errors.vacation = { hours: 'Morate unijeti broj sati veći od 0' };
      }
      if (vacation.hours >= 12) {
        errors.vacation = { hours: 'Nije moguće unjeti više od 12 sati' };
      }
    }
    if (first) {
      if (first.start > first.end) {
        errors.first = { end: 'Početno vrijeme dolaska mora biti prije završnog' };
      }
    }
    if (second) {
      if (second.start > second.end) {
        errors.second = { end: 'Početno vrijeme dolaska mora biti prije završnog' };
      }
    }
    if (!hasErrors(errors)) {
      return null;
    }
    return errors;
  }

  addRule(rule: AutoFillRule) {
    let ruleValidationErrors = this.validateRule(rule);
    const { vacation, first, second, startDate, endDate } = rule;
    if (!ruleValidationErrors) {
      ruleValidationErrors = {};
    }
    if (!hasErrors(ruleValidationErrors)) {
      if (vacation) {
        const vacationMoments = getMomentsRange(startDate, endDate)
          .filter(isMomentInSelectedDays(rule.days));
        vacationMoments.forEach((moment) => {
          const dateString = moment.format(DATE_FORMAT);
          if (!this.vacation[dateString]) {
            this.vacation[dateString] = true;
          } else if (ruleValidationErrors) {
            ruleValidationErrors['startDate'] = 'Preklapanje s prethodno unesenim godišnjim odmorom';
            ruleValidationErrors['endDate'] = 'Preklapanje s prethodno unesenim godišnjim odmorom';
          }
        });
      }
      if (first) {
        const firstMoments = getMomentsRange(startDate, endDate)
          .filter(isMomentInSelectedDays(rule.days));
        firstMoments.forEach((moment) => {
          const dateString = moment.format(DATE_FORMAT);
          if (!this.first[dateString]) {
            this.first[dateString] = true;
          } else if (ruleValidationErrors) {
            ruleValidationErrors['startDate'] = 'Preklapanje s prethodno unesenim pravilom';
            ruleValidationErrors['endDate'] = 'Preklapanje s prethodno unesenim pravilom';
          }
        });
      }
      if (second) {
        const secondMoments = getMomentsRange(startDate, endDate)
          .filter(isMomentInSelectedDays(rule.days));
        secondMoments.forEach((moment) => {
          const dateString = moment.format(DATE_FORMAT);
          if (!this.second[dateString]) {
            this.second[dateString] = true;
          } else if (ruleValidationErrors) {
            ruleValidationErrors['startDate'] = 'Preklapanje s prethodno unesenim pravilom';
            ruleValidationErrors['endDate'] = 'Preklapanje s prethodno unesenim pravilom';
          }
        });
      }
    }
    if (!hasErrors(ruleValidationErrors)) {
      this.summedRulesErrors.push(null);
    } else {
      this.summedRulesErrors.push(ruleValidationErrors);
    }
    this.summedRules.push(rule);
  };

  getValidationErrors() {
    return this.summedRulesErrors;
  }

  hasErrors() {
    return !some(this.summedRulesErrors, hasErrors);
  }

  getDescription() {
    const descriptions = [];
    if (!this.summedRules.length) {
      return 'Ne';
    } else {
      descriptions.push('Da');
    }
    const rulesCount = this.summedRules.length;
    if (rulesCount === 1) {
      descriptions.push('1 pravilo');
    } else {
      descriptions.push(`${rulesCount} pravila`);
    }

    const vacationDays = Object.keys(this.vacation).length;
    if (vacationDays > 0) {
      if (vacationDays % 10 === 1) {
        descriptions.push(`${vacationDays} dan godišnjeg`);
      } else {
        descriptions.push(`${vacationDays} dana godišnjeg`);
      }
    }

    return descriptions.join(', ');
  }
}

export default RulesSummary;
