import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { Employer } from '../../../../../types';
import { useSelector } from 'react-redux';
import { getFormData } from '../../../../../state/formSlice';
import RulesSummary from '../../EmployerFlyout/utils/rulesSummary';

interface CompanyEmployersTableProps {
  employers: Employer[];
  onRemove: (e: Employer) => void,
  onEdit: (e: Employer) => void,
}

const CompanyEmployersTable: React.FC<CompanyEmployersTableProps> = ({
  employers,
  onRemove,
  onEdit,
}) => {
  const { year } = useSelector(getFormData);
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Ime</th>
          <th>OIB</th>
          <th>Fond Sati</th>
          <th>Automatsko popunjavanje</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {employers.map((employer) => (
          <tr key={employer.id}>
            <td>{employer.name}</td>
            <td>{employer.OIB}</td>
            <td>
              {/* Display the hours properties */}
              {employer.hours.daily && <div>Dnevno: {employer.hours.daily}</div>}
              {employer.hours.weekly && <div>Tjedno: {employer.hours.weekly}</div>}
              {employer.hours.monthly && <div>Mjesečno: {employer.hours.monthly}</div>}
            </td>
            <td>{(new RulesSummary(employer.autoFillRules, year).getDescription())}</td>
            <td className='frow jc'>
              <Button
                className="btn btn-sm btn-primary mgr"
                onClick={() => { onEdit(employer); }}
              >Izmjeni</Button>
              <Button
                className="btn btn-sm btn-danger"
                onClick={() => { onRemove(employer); }}
              >Obriši</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CompanyEmployersTable;
